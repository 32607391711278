import React, { useContext } from 'react'
import { Container, ThemeContext } from 'components/common'
import Envelope from 'assets/about/envelope.svg'
import Phone from 'assets/about/phone.svg'
import Marker from 'assets/about/marker.svg'
import EnvelopeWhite from 'assets/about/envelope-white.svg'
import PhoneWhite from 'assets/about/phone-white.svg'
import MarkerWhite from 'assets/about/marker-white.svg'
import { Wrapper, Img, P, DetailsContainer, Text } from './styles'

export const Details = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <Wrapper as={Container}>
      <Text theme={theme}>
        I’m Gaurav Abbhi, also known as Karan, I’m a self-taught full stack JavaScript developer
        and UI/UX Designer and full stack JavaScript developer. I'm really
        interested in Technology & solving technical problems.
      </Text>
  <Text theme={theme}>
      Currently I'm working remotely as a full-stack javascript developer.
    {/*running agency*/}
    {/*<a*/}
    {/*  href="https://yerish.tech"*/}
    {/*  target="_blank"*/}
    {/*  rel="noopener noreferrer"*/}
    {/*> {' '}*/}
    {/*  Yerish Technologies*/}
    {/*</a>*/}
    </Text>
      <Text theme={theme}>
        For business inquiries feel free to get in touch with me at:
      </Text>
      <>
        <P theme={theme}>
          <Img src={theme === 'dark' ? EnvelopeWhite : Envelope} alt="email" />
          gaurav.abbhi@me.com
        </P>
        <P theme={theme}>
          <Img src={theme === 'dark' ? PhoneWhite : Phone} alt="phone" />
          +91-9915792839
        </P>
        <P theme={theme}>
          <Img src={theme === 'dark' ? MarkerWhite : Marker} alt="address" />
          Brookefield, Bengaluru, India
        </P>
      </>
    </Wrapper>
  )
}
